import Link from 'next/link'
import absoluteUrl from 'next-absolute-url'
import clsx from 'clsx'

import Layout from '../components/layout'
import styles from '../styles/Home.module.scss'
import OrderButton from '../components/orderButton'
import AnimatedImage from '../components/animatedImage'
import Bubbles from '../components/bubbles'

import frendliestRed from '../public/static/images/the-worlds-friendliest-red.png';
import greenHome from '../public/static/images/green-home.png';
import greenSauce from '../public/static/images/green-sauce.png';
import yellowHome from '../public/static/images/yellow-home.png';
import yellowSauce from '../public/static/images/yellow-sauce.png';
import redHome from '../public/static/images/red-home.png';
import redSauce from '../public/static/images/red-sauce.png';
import everyMealGreen from '../public/static/images/make-every-meal-green-text.png';

const Home = ({origin}) => {

  return (
    <>
      <Bubbles yellowPage></Bubbles>
      <Layout origin={origin} homePage>
        <div className={styles.grid}>
          <div className={clsx(styles['title-wrap'], styles['col2-start'])}>
              <img className={styles.title} src={frendliestRed} alt="The World's Friendlist Hot Sauce" />
            </div>
        </div>
        <div>
          <h1 className={styles['middle-text']}>The first-ever hot sauce for kids! Inspired by young Hot Ones fans, we set out to create a sauce that lets them live the Hot Ones tradition on the foothills of Mt. Scoville. Featuring the wholesome flavors they crave, a friendly label and easy to use squeeze bottle, Hot Ones Jr. has just-there heat that encourages kids to try new foods and get excited about eating! </h1>
        </div>
        <div className={styles['order-button-top']}>
            <OrderButton className='order-button-home' mobileOnly></OrderButton>
        </div>
        <div className={clsx(styles.grid, styles['negative-space'])}>
          <div className={clsx(styles.product, styles['product-green'])}>
          <Link href="/the-green-sauce" passHref>
              <div className={styles['product-sauce']}>
                <div className={clsx(styles['product-wrap'], styles['product-wrap-green'])}>
                  <img className={styles['product-image']} src={greenHome} alt="jumping pepper and apple"/>
                  <a className={clsx(styles['learn-button-link'], styles['learn-button-green'])}>Learn More</a>
                </div>

                <div className={styles['product-side-wrap']}>
                  <img className={styles['product-image-sauce']} src={greenSauce} alt="mild jalapeno and sweet apple" />
                </div>
              </div>
            </Link>
            <div className={styles['order-button-wrapper']}>
              <OrderButton className='order-button-home' mobileOnly></OrderButton>
            </div>
          </div>

          <div className={clsx(styles.product, styles['product-yellow'])}>
          <Link href="/the-yellow-sauce" passHref>
              <div className={styles['product-sauce']}>
                <div className={clsx(styles['product-wrap'], styles['product-wrap-yellow'])}>
                  <img className={styles['product-image']} src={yellowHome} alt="pepper and mango on a beach" />
                  <a className={`${styles['learn-button-link']} ${styles['learn-button-yellow']}`}>Learn More</a>
                </div>
                <div className={styles['product-side-wrap']}>
                  <img className={styles['product-image-sauce']} src={yellowSauce} alt="scotch bonnet & juicy mango" />
                </div>
              </div>
            </Link>
            <div className={styles['order-button-wrapper']}>
              <OrderButton className='order-button-home' mobileOnly></OrderButton>
            </div>
          </div>

          <div className={clsx(styles.product, styles['product-red'])}>
          <Link href="/the-red-sauce" passHref>
            <div className={styles['product-sauce']}>
              <div className={clsx(styles['product-wrap'], styles['product-wrap-red'])}>
                <img className={styles['product-image']} src={redHome} alt="the red - ghost pepper and carrot" />
                <a className={`${styles['learn-button-link']} ${styles['learn-button-red']}`}>Learn More</a>
              </div>
              <div className={styles['product-side-wrap']}>
                <img className={styles['product-image-sauce']} src={redSauce} alt="the red sauce" />
              </div>
            </div>
          </Link>
          </div>
        </div>

        <div className={clsx(styles.grid, styles['negative-space'], styles['negative-space--bottom'])}>
          <div className={clsx(styles['title-wrap'], styles['col2-start'])}>
              <div className={styles['order-button-botton']}>
                <OrderButton className={'order-button-home'}></OrderButton>
              </div>
              <img className={styles.cta} src={everyMealGreen} alt="Make Every Meal an Adventure"></img>
              <AnimatedImage className={`${styles.seal} animated-seal`} src="seal" alt="kid friendly badge" />
            </div>
        </div>
      </Layout>
    </>
  )
}

export async function getServerSideProps(context) {
  const { origin } = absoluteUrl(context.req);

  return {
    props: {
      origin
    }
  }
}

export default Home
