import styles from './OrderButton.module.scss'

export default function OrderButton({ ...props }) {
    return (
        <>
            <a href="https://heatonist.com/collections/hot-ones-jr" target="_blank" rel="noreferrer" className={`${styles['order-button']} ${styles[props.className]} ${props.mobileOnly && styles["additional-button"]}`}>
                {props.text ? props.text : 'Order on Heatonist.com'}
            </a>
        </>
    )
}

